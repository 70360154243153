(function ($) {
  "use strict";

  //   This is for the certificate functionality only.
  //   Functionality relating to Eloqua is in the fbc-eloqua.js File.

  Drupal.behaviors.fbcCertificate = {
    attach: function (context, settings) {
      var formFilled = getCookie("fbc-wf");

      if (formFilled == null) {
        setCookie("fbc-wf", "unfilled", 7);
      }
      // Makes content gated and displays a certificate if available.
      // Checks for a cookie to see if a gate has been cleared, if so, clear all gates.

      // The cookie lasts 7 days, as confirmed with Dan Wold.
      // It is ok that users will have access to all videos for this period of time.

      function hideRequestForm() {
        $(".ungated-form").removeClass("visible").addClass("hidden");
        $(".ungated-form .form-wrapper .row .links").css("display", "none");
        $(".gated-form").removeClass("visible").addClass("hidden");
        $(".gated-form .form-wrapper .row .links").css("display", "none");
      }

      function blockVideo() {
        $(".video-container").addClass("video-container-blocked");
      }

      function unblockVideo() {
        $(".video-container").removeClass("video-container-blocked");
      }

      function showCertificateFormIntro() {
        $(".certificate-form-intro").removeClass("hidden").addClass("visible");
      }

      function hideCertificateFormIntro() {
        $(".certificate-form-intro").removeClass("visible").addClass("hidden");
      }

      function hideCertificateForm() {
        $(".certificate-form").removeClass("visible").addClass("hidden");
      }

      function showCertificateForm() {
        $(".certificate-form").removeClass("hidden").addClass("visible");
      }

      // Check page if a form is required.
      if ($(".gated-form").length > 0) {
        blockVideo();
        hideCertificateFormIntro();
        hideCertificateForm();

        // If a form is in markup on the page, check page if the form has been filled.
        if (formFilled === "filled") {
          // If the cookie says the form has been filled, don't show the form.
          hideRequestForm();
          unblockVideo();
          showCertificateFormIntro();
        }

        // If a form is NOT in markup on the page, show the form and set a cookie.
        try {
          if (context.html().indexOf("webform-confirmation")) {
            setCookie("fbc-wf", "filled", 7);
            hideRequestForm();
            unblockVideo();
            showCertificateFormIntro();
          }
        } catch (error) {
          blockVideo();
          hideCertificateFormIntro();
          hideCertificateForm();
        }

        // Check page if video is supposed to be displayed when there is a form.
        if (
          $(".field-name-field-video").length > 0 &&
          formFilled === "filled"
        ) {
          // If the video is displayed on the page and the form has been filled, unblock video.
          hideRequestForm();
          unblockVideo();
        }
      }

      if ($(".gated-form").length < 1) {
        // There is no form on the page, but there may or may not be a video.

        // If there is a video on the page, show the form.
        // Let the video player change the JS on if the form is displayed.
        if ($(".field-name-field-video").length > 0) {
          showCertificateFormIntro();
          hideCertificateForm();
        }

        // If there is NOT a video on the page, just show the form.
        if ($(".field-name-field-video").length < 1) {
          hideCertificateFormIntro();
          showCertificateForm();
        }
      }

      /**
       * This section is for handling the certificate, copying fields and actions
       * after the user has submitted the certificate form.
       */

      // Get the title of the event for the certificate.
      if ($("#certificate-node-form").length) {
        var webinarTitle = $(".field-name-field-h1-title h1").text();

        if ($(".field-name-field-event").length) {
          $('input[name="field_event[und][0][value]"]').val(webinarTitle);
        }

        var userFirst = $(
          'input[name="field_first_name[und][0][value]"]'
        ).val();

        var userLast = $('input[name="field_last_name[und][0][value]"]').val();

        $('input[name="title_field[und][0][value]"]').val(
          webinarTitle + "-" + userFirst + "-" + userLast
        );
      }

      // Gather fields from the video node to enter on the certificate form.
      if ($(".node-type-video").length) {
        if ($(".node-certificate-form").length) {
          var webinarTitle = $(".field-name-field-h1-title h1").text();
          var webinarBrand = $(
            ".field-name-field-manufacturer .field-item"
          ).text();
          var webinarGUID = $(
            ".field-name-field-eloqua-campaign-id .field-item"
          ).text();

          var today = new Date();
          var dd = today.getDate();
          var mm = today.getMonth() + 1;
          var yyyy = today.getFullYear();
          if (dd < 10) {
            dd = "0" + dd;
          }
          if (mm < 10) {
            mm = "0" + mm;
          }

          today = yyyy + "-" + mm + "-" + dd;

          if ($(".field-name-field-webinar-date").length) {
            var webinarDate = $(
              ".field-name-field-webinar-date .date-display-single"
            ).text();
          } else {
            var webinarDate = today;
          }

          $('input[name="field_event[und][0][value]"]').val(webinarTitle);
          $('input[name="title_field[und][0][value]"]').val(webinarTitle);
          $('input[name="field_certificate_date[und][0][value][date]"]').val(
            webinarDate
          );
          $('input[name="field_webinar_brand[und][0][value]"]').val(
            webinarBrand
          );
          $('input[name="field_crm_guid[und][0][value]"]').val(webinarGUID);
        }
      }

      // Hide the certificate create form if it has been created.
      if ($(".node-type-certificate").length) {
        if ($(".node-certificate-form .messages.status").length) {
          $(".node-certificate-form .messages").css("display", "none");

          // Get the url from the status message.
          var certificateNode = $("#certificate-node").text();

          var certificateLink = "/printpdf/node/" + certificateNode;
          window.location.replace(certificateLink);

          var certificateButton =
            '<a class="button" href="/printpdf/node/' +
            certificateNode +
            '">Download Certificate</a>';

          $(".field-name-receive-your-certificate form").css("display", "none");
          $(".field-name-receive-your-certificate")
            .once()
            .after(
              '<div class="certificate-note"><h2>Congratulations</h2>' +
                "<p>If you are not automatically redirected to your certificate, please click here:</p>" +
                certificateButton
            ) + "</div>";
        }
      }
    },
  };
})(jQuery);
